import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ROUTE_KEYS, STORAGE_KEYS } from '../helpers/constants';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

import { CoKitchenType, CokitchenTypeEnum } from '../helpers/types';
import homeStore from '../stores/homeStore';

const ProtectedRoute = observer(function ProtectedRoute({
  component: Component,
  authenticated,
  allowAdmin,
  panelRole,
  ...rest
}: any) {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE) as ENUM_KITCHEN_ROLE;
  const token: any = localStorage.getItem(STORAGE_KEYS.TOKEN);

  const cokitchenId = localStorage.getItem(STORAGE_KEYS.COKITCHEN) ?? '';
  const isPopUpKitchen = localStorage.getItem(STORAGE_KEYS.IS_POPUP_ACCOUNT) ?? '';

  const { getCokitchenById } = homeStore;

  const fetchActiveCokitchen = async () => {
    if (isPopUpKitchen === 'true') return;
    const cokitchen: CoKitchenType = await getCokitchenById(cokitchenId);
    localStorage.setItem(
      STORAGE_KEYS.IS_POPUP_ACCOUNT,
      JSON.stringify(cokitchen.cokitchen_type === CokitchenTypeEnum.pop)
    );
  };

  useEffect(() => {
    fetchActiveCokitchen();
  }, []);

  return (
    <Route
      {...rest}
      render={(props: JSX.IntrinsicAttributes) =>
        Boolean(token) &&
        (role === panelRole || (Array.isArray(panelRole) && panelRole.includes(role))) ? (
          <Route render={() => <Component {...props} />} />
        ) : (
          <Redirect to={ROUTE_KEYS.LOGIN} />
        )
      }
    />
  );
});
export default ProtectedRoute;
