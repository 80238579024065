export const surgeReasons = [
  { value: '0', label: 'No surge', alt: '0.0' },
  { value: '5', label: '+5 mins', alt: '1.0' },
  { value: '10', label: '+10 mins', alt: '1.0' },
  { value: '15', label: '+15 mins', alt: '1.0' },
  { value: '20', label: '+20 mins', alt: '1.0' },
  { value: '25', label: '+25 mins', alt: '1.0' },
  { value: '30', label: '+30 mins', alt: '1.0' }
];

export const availableMealTimes = [
  { label: '5 Minutes', value: 5, type: 'minutes' },
  { label: '10 Minutes', value: 10, type: 'minutes' },
  { label: '15 Minutes', value: 15, type: 'minutes' },
  { label: '20 Minutes', value: 20, type: 'minutes' },
  { label: '25 Minutes', value: 25, type: 'minutes' },
  { label: '30 Minutes', value: 30, type: 'minutes' },
  { label: '35 Minutes', value: 35, type: 'minutes' },
  { label: '40 Minutes', value: 40, type: 'minutes' },
  { label: '45 Minutes', value: 45, type: 'minutes' },
  { label: '50 Minutes', value: 50, type: 'minutes' },
  { label: '55 Minutes', value: 55, type: 'minutes' },
  { label: '1 Hour', value: 1, type: 'hours' },
  { label: 'Sold Out', value: 0, type: 'minutes' }
];

export const STORAGE_KEYS = {
  TOKEN: 'token',
  USER_ID: 'id',
  EMAIL: 'email',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  ROLE: 'role',
  COKITCHEN: 'cokitchen',
  STAFF_NUMBER: 'staff_number',
  HEADER_BRANDS: 'header_brands',
  PINNED_ORDERS: 'pinned_orders',
  SCREEN_DETAILS: 'screen_details',
  PACKAGING_SCREEN_DETAILS: 'packaging_screen_details',
  IS_BRAND_SPLIT_SCREEN: 'is_brand_split_screen',
  IS_BRAND_PACKAGING_SCREEN: 'is_brand_packaging_screen',
  TEST_MODE: 'test_mode',
  DELIVERY_TYPE: 'delivery_type',
  PACKAGING_STAFF_ID: 'packaging_staff_id',
  IS_POPUP_ACCOUNT: 'is_pop_up_account'
};

export const ROUTE_KEYS = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password/:token',
  SEARCH: '/search',
  KITCHEN: {
    HOME: '/kitchen',
    RECALL: '/kitchen/recall',
    SCHEDULED: '/kitchen/scheduled',
    PREORDER: '/kitchen/preorder',
    PENDING: '/kitchen/pending'
  },
  FRONT_DESK: {
    HOME: '/front-desk',
    DISPATCH: '/front-desk/dispatch',
    UNPAID: '/front-desk/unpaid'
  },
  PACKAGING: {
    HOME: '/packaging',
    RECALL: '/packaging/recall'
  },
  SHOP: {
    HOME: '/shop',
    RECALL: '/shop/recall',
    INVENTORY: '/shop/inventory'
  },
  ADMIN: {
    HOME: '/admin/kitchen',
    RECALL: '/admin/recall',
    DISPATCH: '/admin/dispatch',
    UNPAID: '/admin/unpaid',
    INVENTORY: '/admin/inventory',
    PENDING: '/admin/pending'
  },
  SUPER_ADMIN: {
    HOME: '/super-admin/kitchen',
    RECALL: '/super-admin/recall',
    DISPATCH: '/super-admin/dispatch',
    UNPAID: '/super-admin/unpaid',
    INVENTORY: '/super-admin/inventory'
  }
};

export const FILTER_KEYS = {
  ALL: 'All',
  FOOD: 'FOOD',
  SHOP: 'SHOP',
  PICKUP: 'Pickup',
  DELIVERY: 'Delivery',
  PINNED: 'Pinned',
  PREORDER: 'PreOrder',
  REGULAR: 'Regular'
};

export const SURGE_MINUTES = [5, 10, 15, 20, 25, 30];

export const SOLD_OUT_MINUTES = [10, 20, 30, 40, 50, 60];

export const REJECTION_REASONS = [
  'Items are out of stock',
  'Kitchen is too busy',
  'No riders available',
  'Payment was not confirmed',
  'Other'
];
export const TURN_OFF_REASON = ['Items are out of stock', 'Items are expired', 'Others'];

export const splitScreenColors = [
  '#6d597a',
  '#3B6278',
  '#8C4547',
  '#023047',
  '#8390fa',
  '#3a0ca3',
  '#8e9aaf',
  '#073b4c',
  '#f9c74f',
  '#772e25'
];

export const CARD_HEADER_TAG = {
  shop: { text: 'SHOP', color: '#0EA3FD' }, // blue
  shopReady: { text: '✅ Shop is ready', color: '#0EA3FD' }, // blue
  food: { text: 'FOOD', color: '#F9C320' }, // yellow
  foodReady: { text: '✅ Food is ready', color: '#F9C320' }, // yellow
  late: { text: 'LATE ORDER', color: '#E60A2B' }, // red
  new: { text: 'NEW', color: '#1A1A1A' }, // dark gray
  brandReady: { text: 'Ready✅', color: '#46AE31' }, // green
  loyal: { text: 'Loyal', color: '#7b3269' } // purple
};
