import React, { FC, useEffect, useState } from 'react';
import './styles.scss';
import {
  BaseModal,
  MultiselectItem,
  Button,
  Input,
  Dropdown,
  DropdownList,
  DropdownItem
} from '@cokitchen/cokitchen-components';
import { ReactComponent as FolderIcon } from '../../assets/images/folder-download.svg';
import { withAlert } from 'react-alert';
import dataService from '../../services/data.service';
import { StaffType } from '../../helpers/types';
import { STORAGE_KEYS } from '../../helpers/constants';

interface ExportInventoryModalProps {
  show: boolean;
  options: MultiselectItem[];
  onClose: () => void;
  alert: any;
}

const ClockInModal: FC<ExportInventoryModalProps> = ({ show, onClose, alert }) => {
  const [values, setValues] = useState<MultiselectItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [staffOptions, setStaffOptions] = useState<MultiselectItem[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const timeoutId = setTimeout(() => getStaffs(), 500);
    return () => clearTimeout(timeoutId);
  }, [search]);

  const handleClockIn = async () => {
    setLoading(true);
    try {
      const res: { data?: { data: StaffType } } = await dataService.postAuthData(
        'user/kitchen/staffs/clock-in',
        { staff_id: values[0]?.value, staff_number: password },
        { useOrder: true }
      );
      if (res?.data !== undefined) {
        alert?.show('Clocked in successfully', { type: 'success' });
        localStorage.setItem(STORAGE_KEYS.PACKAGING_STAFF_ID, JSON.stringify(res?.data?.data));
        onClose();
      }
    } catch (e) {
      alert?.show('Clocked in failed', { type: 'error' });
    } finally {
      setLoading(false);
    }
  };
  const getStaffs = async () => {
    if (search === '') return;
    try {
      const res: { data: { data: StaffType[] } } = await dataService.getAuthData(
        'user/kitchen/staffs',
        { search },
        { useOrder: true }
      );
      setStaffOptions(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        res?.data?.data.map((opt) => {
          return {
            text: `${opt?.first_name} ${opt?.last_name}`,
            value: opt?.id
          };
        })
      );
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <BaseModal
      isOpen={show}
      title="Clock In"
      description="Select your name and clock in  to continue."
      titleIcon={<FolderIcon />}
      titleIconColor="info"
      onRequestClose={onClose}
      footer={
        <Button
          color="primary"
          variant="block"
          className="mx-auto"
          loading={loading}
          disabled={password === '' || values.length === 0}
          onClick={handleClockIn} >
          Clock In
        </Button>
      }>
      <div className="mb-[120px] mt-2">
        <Dropdown
          visible={search !== ''}
          onClickOutside={() => {
            setStaffOptions([]);
            setSearch('');
          }}
          content={
            <Input
              type="search"
              value={values[0]?.text ?? search}
              placeholder="Search Staff by name"
              small
              onChange={(e) => {
                if (values.length !== 0) {
                  setValues([]);
                  setSearch('');
                }
                setSearch(e.target.value);
              }}
              className="mb-7 "
            />
          }>
          <DropdownList>
            {staffOptions.length === 0 && (
              <DropdownItem className="text-sm font-medium text-[#2e3031]">
                No user found
              </DropdownItem>
            )}
            {staffOptions.map((opt, index) => (
              <DropdownItem
                key={index}
                className="text-sm font-medium text-[#2e3031]"
                onClick={() => {
                  setValues([opt]);
                  setStaffOptions([]);
                  setSearch('');
                }}>
                {opt?.text}
              </DropdownItem>
            ))}
          </DropdownList>
        </Dropdown>

        <Input
          type="text"
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mt-3 mb-4"
        />
      </div>
    </BaseModal>
  );
};

export default withAlert()(ClockInModal);
