import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { STORAGE_KEYS } from '../helpers/constants';
import { ToastMessage } from '../components/toast-container';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_URL;
const BETA_URL = process.env.REACT_APP_BETA_API_URL;
const ORDER_URL = process.env.REACT_APP_ORDER_API_URL;
const BRAND_URL = process.env.REACT_APP_BRAND_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

class DataService {
  /** *********************************Get data********************* */
  async getAuthData(
    url,
    query = {},
    options = {
      useBeta: false,
      useOrder: false
    }
  ) {
    const { useBeta, useOrder } = options;
    const token = await localStorage.getItem(STORAGE_KEYS.TOKEN);
    const baseUrl = useBeta ? BETA_URL : useOrder ? ORDER_URL : API_URL;
    return axios
      .get(baseUrl + url, {
        params: query,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        return response;
      });
  }

  /** *********************************Get data********************* */
  async getInternalData(
    url,
    query,
    authToken = undefined,
    options = {
      useBeta: false,
      useBrand: false
    }
  ) {
    const { useBeta, useBrand } = options;
    const baseUrl = useBeta ? BETA_URL : useBrand ? BRAND_URL : API_URL;
    const token = authToken || localStorage.getItem(STORAGE_KEYS.TOKEN);

    return axios
      .get(baseUrl + url, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'x-api-key': API_KEY
        },
        params: query
      })
      .then((response) => {
        return response;
      });
  }

  /** *********************************Post data********************* */
  async postAuthData(
    url,
    data,
    options = {
      useBeta: false,
      useOrder: false
    }
  ) {
    const { useBeta, useOrder } = options;
    const baseUrl = useBeta ? BETA_URL : useOrder ? ORDER_URL : API_URL;

    const token = await localStorage.getItem(STORAGE_KEYS.TOKEN);
    return axios
      .post(baseUrl + url, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      .then((response) => {
        return response;
      });
  }

  /** *********************************Post data********************* */
  async postInternalData(url, data) {
    const token = await localStorage.getItem(STORAGE_KEYS.TOKEN);
    return axios
      .post(API_URL + url, data, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'x-api-key': API_KEY
        }
      })

      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast(<ToastMessage type="error" message={error?.response?.data?.error} />);
      });
  }

  /** *********************************Put data********************* */
  async putAuthData(
    url,
    data,
    options = {
      useBeta: false
    }
  ) {
    const { useBeta } = options;
    const token = await localStorage.getItem(STORAGE_KEYS.TOKEN);
    const baseUrl = useBeta ? BETA_URL : API_URL;
    return axios
      .put(baseUrl + url, data, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      .then((response) => {
        return response;
      });
  }

  /** *********************************Put data********************* */
  async deleteAuthData(
    url,
    options = {
      useBeta: false
    }
  ) {
    const { useBeta } = options;
    const baseUrl = useBeta ? BETA_URL : API_URL;

    const token = await localStorage.getItem(STORAGE_KEYS.TOKEN);
    return axios
      .delete(baseUrl + url, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        return response;
      });
  }
}

export default withRouter(new DataService());
