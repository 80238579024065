import { observable, action, runInAction, makeAutoObservable } from 'mobx';
import moment from 'moment';
import errorHandler from '../helpers/errorHandler';
import {
  DISPATCH_ORDER_URL,
  END_ORDER_URL,
  END_SHOP_ORDER_URL,
  GET_COMPLETED_ORDER_URL,
  GET_REJECTED_ORDER_URL,
  RECALL_ORDER_URL,
  REJECT_ORDER_URL,
  START_ORDER_URL,
  START_SHOP_ORDER_URL,
  SET_PAID_ORDER_URL,
  TURN_OFF_MULTIPLE_ITEMS,
  UPDATE_MEALS,
  COKITCHEN_DELIVERY_SURGE_URL,
  GET_BRANDS_URL,
  GET_ADDONS_URL,
  GET_COKITCHENS_URL,
  NO_OF_SCREENS,
  SPECIFIC_COKITCHENS_URL,
  GET_ADDONS,
  BRAND_IS_READY
} from '../helpers/apiUrls';
import dataService from '../services/data.service';
import { STORAGE_KEYS } from '../helpers/constants';

export class HomeStore {
  @observable logistics = {};

  @observable allOrders = [];
  @observable allPaidOrders = [];
  @observable pendingOrders = [];
  @observable unpaidOrders = [];
  @observable trackingOrders = [];
  @observable settledHistoryOrders = [];

  @observable completedOrders = [];
  @observable specificCokitchens = [];
  @observable rejectedOrders = [];
  @observable searchMatchResults = [];
  @observable brands = [];
  @observable addons = [];
  @observable headerBrands = [];
  @observable isBrandSplitScreen = false;
  @observable isBrandPackagingScreen = false;
  @observable activeHeaderBrands = ['ALL'];

  @observable dataCached = false;
  @observable dataFBCached = false;

  @observable rejectedDataCached = false;
  @observable specificCokitchensDataCached = false;

  @observable historyDataCached = false;

  @observable addonDataCached = false;

  @observable settleHistoryDataCached = false;
  @observable brandsDataCached = false;
  @observable count = 0;
  @observable mealUpdateLoading = false;
  @observable noOfScreensLoading = false;
  @observable switchMealLoading = false;
  @observable addOnLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action updateCount = (item) => {
    this.count += 1;
  };

  @action updateHeaderBrands = (brands) => {
    this.headerBrands = brands;
  };

  @action updateActiveHeaderBrands = (brands) => {
    localStorage.setItem(STORAGE_KEYS.HEADER_BRANDS, JSON.stringify(brands));
    this.activeHeaderBrands = brands;
  };

  @action updateIsBrandSplitScreen = (value) => {
    localStorage.setItem(STORAGE_KEYS.IS_BRAND_SPLIT_SCREEN, JSON.stringify(value));
    this.isBrandSplitScreen = value;
  };

  @action updateIsBrandPackagingScreen = (value) => {
    localStorage.setItem(STORAGE_KEYS.IS_BRAND_PACKAGING_SCREEN, JSON.stringify(value));
    this.isBrandPackagingScreen = value;
  };

  @action updateSearchMatches = (item) => {
    this.searchMatchResults = item;
  };

  @action updatePendingOrders = (item, itemTwo) => {
    this.pendingOrders = item;
    this.allOrders = itemTwo;
    this.dataFBCached = true;
  };

  @action updatePaidOrders = (item) => {
    this.allPaidOrders = item;
    this.dataFBCached = true;
  };

  @action updateUnPaidOrders = (item) => {
    this.unpaidOrders = item;
    this.dataFBCached = true;
  };

  @action updateTrackingOrders = (item) => {
    this.trackingOrders = item;
    this.dataFBCached = true;
  };

  @action updateStoreItem = (item, value) => {
    this[item] = value;
  };

  getLogistics = async () => {
    try {
      const response = await dataService.getInternalData('internal/cokitchen-explore-keyword');

      runInAction(async () => {
        const res = response.data && response.data;
        const moments = res && (await res.data.map((x) => moment(x.created_at)));
        const maxDate = moment.max(moments).format('D MMM YY');
        const minDate = moment.min(moments).format('D MMM YY');

        this.dataCached = true;
        res.max_date = maxDate;
        res.min_date = minDate;
        this.logistics = res;
      });
    } catch (error) {
      console.error(error);
    }
  };

  startKitchenOrder = async (alert, data, shouldAlert) => {
    try {
      const response = await dataService.postAuthData(START_ORDER_URL + data, null);
      let res;
      runInAction(() => {
        res = response && response.data;

        if (res) {
          shouldAlert &&
            alert &&
            alert.show('Order started successfully!', {
              type: 'success'
            });

          this.dataCached = true;
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  startShopOrder = async (alert, data, shouldAlert) => {
    try {
      const response = await dataService.postAuthData(START_SHOP_ORDER_URL + data, null);
      let res;
      runInAction(() => {
        res = response && response.data;

        if (res) {
          shouldAlert &&
            alert &&
            alert.show('Order started successfully!', {
              type: 'success'
            });

          this.dataCached = true;
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  setPaidOrder = async (alert, data, shouldAlert) => {
    try {
      const response = await dataService.postAuthData(SET_PAID_ORDER_URL + data, null);
      let res;
      runInAction(() => {
        res = response && response.data;

        if (res) {
          shouldAlert &&
            alert &&
            alert.show('Order confirmed successfully!', {
              type: 'success'
            });

          this.dataCached = true;
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  endKitchenOrder = async (alert, data, shouldAlert) => {
    try {
      const response = await dataService.postAuthData(END_ORDER_URL + data, null);
      let res;
      runInAction(() => {
        res = response && response.data;
        if (res) {
          shouldAlert &&
            alert &&
            alert.show('Order ended successfully!', {
              type: 'success'
            });

          this.dataCached = true;
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  endShopOrder = async (alert, data, shouldAlert) => {
    try {
      const response = await dataService.postAuthData(END_SHOP_ORDER_URL + data, null);
      let res;
      runInAction(() => {
        res = response && response.data;

        if (res) {
          shouldAlert &&
            alert &&
            alert.show('Order ended successfully!', {
              type: 'success'
            });

          this.dataCached = true;
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  brandIsReady = async (data) => {
    try {
      const response = await dataService.postAuthData(BRAND_IS_READY, data);
      return response.data;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  rejectOrder = async (alert, id, data, shouldAlert) => {
    try {
      const response = await dataService.postAuthData(REJECT_ORDER_URL + id, data);
      let res;
      runInAction(() => {
        res = response && response.data;

        if (res) {
          shouldAlert &&
            alert &&
            alert.show('Order rejected successfully!', {
              type: 'success'
            });

          this.dataCached = true;
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  updateMeal = async (alert, data, is_addon) => {
    // this.updateStoreItem("mealUpdateLoading", true);
    this.mealUpdateLoading = true;

    data = {
      active: data?.active,
      sold_out: data?.sold_out,
      availability_soon_date: data?.availability_soon_date,
      meal_id: data?.meal_ids?.[0],
      cokitchen_id: data?.cokitchen_id,
      addon_id: data?.addon_ids?.[0]
    };
    data = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== null && value !== undefined)
    );

    try {
      const url = is_addon ? 'kitchen/related-addon' : 'kitchen/related-meal';
      const response = await dataService.putAuthData(url, { data: [data] });

      runInAction(() => {
        if (response && response.data) {
          alert.show('Meal updated successfully!', {
            type: 'success'
          });
          this.dataCached = true;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    } finally {
      // this.updateStoreItem("mealUpdateLoading", false);
      this.mealUpdateLoading = false;
    }
  };

  recallOrder = async (alert, data, shouldAlert) => {
    try {
      const response = await dataService.postAuthData(RECALL_ORDER_URL + data, null);
      let res;
      runInAction(() => {
        res = response && response.data;

        if (res) {
          shouldAlert &&
            alert &&
            alert.show('Order recalled successfully!', {
              type: 'success'
            });

          this.dataCached = true;
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  turnOffAllItems = async (alert, data, shouldAlert) => {
    try {
      const response = await dataService.postAuthData(TURN_OFF_MULTIPLE_ITEMS, data);
      let res;
      runInAction(() => {
        res = response && response.data;

        if (res) {
          shouldAlert &&
            alert &&
            alert.show('Items successfully turned off!', {
              type: 'success'
            });
          this.dataCached = true;
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  updateMeals = async (alert, data, shouldAlert) => {
    try {
      const response = await dataService.postAuthData(UPDATE_MEALS, data);
      let res;
      runInAction(() => {
        res = response && response.data;

        if (res) {
          shouldAlert &&
            alert &&
            alert.show('Items successfully turned off!', {
              type: 'success'
            });
          this.dataCached = true;
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  searchMeal = async (alert, data, shouldAlert) => {
    try {
      const response = await dataService.postAuthData(RECALL_ORDER_URL, data);
      let res;
      runInAction(() => {
        res = response && response.data;
        if (res) {
          shouldAlert &&
            alert &&
            alert.show('Meals returned successfully!', {
              type: 'success'
            });

          this.dataCached = true;
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  dispatchOrder = async (alert, data, shouldAlert) => {
    try {
      const response = await dataService.postAuthData(DISPATCH_ORDER_URL + data, null);
      let res;
      runInAction(() => {
        res = response && response.data;

        if (res) {
          shouldAlert &&
            alert &&
            alert.show('Order dispatched successfully!', {
              type: 'success'
            });

          this.dataCached = true;
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  getCompletedOrders = async (alert, shouldAlert) => {
    try {
      const response = await dataService.getAuthData(GET_COMPLETED_ORDER_URL);
      let res;
      runInAction(() => {
        res = response && response.data && response.data.data;

        this.completedOrders = res;

        shouldAlert &&
          alert &&
          alert.show('Completed orders loaded successfully!', {
            type: 'success'
          });

        this.historyDataCached = true;
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  getCokitchens = async (alert, shouldAlert) => {
    try {
      const response = await dataService.getInternalData(
        GET_COKITCHENS_URL,
        '',
        {},
        { useBrand: true }
      );

      // let res;
      // runInAction(() => {
      const res = response && response.data && response.data.data;
      // });
      return res;
    } catch (error) {
      errorHandler(error);
    }
  };

  getCokitchenById = async (cokitchenId) => {
    const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
    try {
      const response = await dataService.getInternalData('cokitchens/' + cokitchenId, '', token, {
        useBrand: true
      });

      // let res;
      // runInAction(() => {
      const res = response && response.data && response.data.data;
      // });
      return res;
    } catch (error) {
      errorHandler(error);
    }
  };

  // cokitchen specific

  getSpecificCokitchens = async (alert, shouldAlert) => {
    try {
      const response = await dataService.getInternalData(SPECIFIC_COKITCHENS_URL);
      let res;
      runInAction(() => {
        res = response && response.data && response.data.cokitchens;

        this.specificCokitchens = res;
        shouldAlert &&
          alert &&
          alert.show('Cokitchen loaded successfully!', {
            type: 'success'
          });

        this.specificCokitchensDataCached = true;
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  getRejectedOrders = async (alert, shouldAlert) => {
    try {
      const response = await dataService.getAuthData(GET_REJECTED_ORDER_URL);
      let res;
      runInAction(() => {
        res = response && response.data && response.data.data;

        this.rejectedOrders = res;
        shouldAlert &&
          alert &&
          alert.show('Rejected orders loaded successfully!', {
            type: 'success'
          });

        this.rejectedDataCached = true;
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  getBrands = async (alert) => {
    let res;
    try {
      const response = await dataService.getInternalData(GET_BRANDS_URL);

      runInAction(() => {
        res = response.data && response.data.data;
        this.brands = res;
        alert &&
          alert.show('Brands loaded successfully!', {
            type: 'success'
          });

        this.brandsDataCached = true;
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  getMealAddons = async (data, alert) => {
    let response = [];
    try {
      response = await dataService.postInternalData(GET_ADDONS_URL, data);
      runInAction(() => {
        if (response && response.data) {
          return response.data;
        }
      });
      const res = response?.data?.data;
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  postDeliveryValues = async (alert, data, message) => {
    try {
      const response = await dataService.postAuthData(COKITCHEN_DELIVERY_SURGE_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show(message, {
            type: 'success'
          });
          this.dataCached = true;
          return response.data;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  // UPDATE NUMBER OF SCREENS

  updateNumberOfScreens = async (alert, data) => {
    this.updateStoreItem('noOfScreensLoading', true);
    // this.noOfScreensLoading = true;
    try {
      const response = await dataService.putAuthData(NO_OF_SCREENS, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show('Meal updated successfully!', {
            type: 'success'
          });
          this.dataCached = true;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    } finally {
      this.updateStoreItem('noOfScreensLoading', false);
      // this.noOfScreensLoading = false;
    }
  };

  //
  getBrandAddons = async (id, alert) => {
    this.addOnLoading = true;
    try {
      const response = await dataService.getInternalData(GET_ADDONS + id);

      let res;
      runInAction(() => {
        res = response.data && response.data.meals;
        this.addons = res;
        alert &&
          alert.show('Brands loaded successfully!', {
            type: 'success'
          });
        this.addonDataCached = true;
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    } finally {
      this.addOnLoading = false;
    }
  };
}
export default new HomeStore();
