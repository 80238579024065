import React, { FC, ReactNode, useEffect, useState } from 'react';
import { LayoutContext } from './context';
import { FILTERS_BY_ROUTE, FilterType } from '../../pages/live/filters';
import { OrderType } from '../../helpers/types';
import { STORAGE_KEYS } from '../../helpers/constants';
import { activeRoleMapping, activeRoleOrderValidity } from '../../services/role_validation';
import { ENUM_PAGES } from '../../helpers/enums';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';
import { SocketIOConnection } from '../../helpers/client_library';
import { getOrderLatestTime } from '../../helpers/functions';
import errorHandler from '../../helpers/errorHandler';
import { observer } from 'mobx-react-lite';
import { filterSearchValue, getPrefilteredFilters } from '../../pages/live/fns.service';
import {
  filterMealBrands,
  filterOrdersByActiveFilters,
  getActiveFilters,
  getFilterCount,
  getMealBrands,
  sortOrdersByLatestTime
} from './utils';
import homeStore from '../../stores/homeStore';

const LayoutProvider: FC<{ children: ReactNode }> = observer(({ children }) => {
  const {
    activeHeaderBrands,
    updateActiveHeaderBrands,
    updateIsBrandSplitScreen,
    updateIsBrandPackagingScreen
  } = homeStore;

  const [searchValue, setSearchValue] = useState('');
  const [orders, setOrders] = useState([] as OrderType[]);
  const defaultDeliveryType = localStorage.getItem(STORAGE_KEYS.DELIVERY_TYPE) ?? '';
  const [pinnedOrders, setPinnedOrders] = useState([] as OrderType[]);
  const [activePinFilter, setActivePinFilter] = useState<FilterType | null>(null);
  const [filters, setFilters] = useState<FilterType[]>([]);
  const [activeFilter, setActiveFilter] = useState<FilterType>();

  const [prefilteredOrders, setPrefilteredOrders] = useState<OrderType[]>([]);
  const [socketConfig, setSocketConfig] = useState<{ roomName: string; page: ENUM_PAGES | null }>({
    roomName: '',
    page: null
  });
  const screenDetails = JSON.parse(localStorage.getItem(STORAGE_KEYS.SCREEN_DETAILS) as string);
  const screen_id: string = screenDetails?.id;
  const role = localStorage.getItem(STORAGE_KEYS.ROLE) as ENUM_KITCHEN_ROLE;
  const [loading, setLoading] = useState(false);

  const [testMode, setTestMode] = useState(
    JSON.parse(localStorage.getItem(STORAGE_KEYS.TEST_MODE) ?? 'false') as boolean
  );

  const [deliveryType, setDeliveryType] = useState<string>(
    defaultDeliveryType === '' ? '' : (JSON.parse(defaultDeliveryType) as string)
  );

  const [receiptToPrint, setReceiptToPrint] = useState<{
    ref: any;
    shouldPrint: boolean;
    order: OrderType | null;
  }>({
    ref: null,
    shouldPrint: false,
    order: null
  });
  const cokitchenId = localStorage.getItem(STORAGE_KEYS.COKITCHEN);
  const packagingScreenDetails = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.PACKAGING_SCREEN_DETAILS) as string
  );
  const packaging_screen_id: string = packagingScreenDetails?.id;
  const initHeaderBrands = () => {
    const isSplitScreen = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.IS_BRAND_SPLIT_SCREEN) as string
    ) as boolean;
    const isPackagingScreen = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.IS_BRAND_PACKAGING_SCREEN) as string
    );
    updateIsBrandPackagingScreen(isPackagingScreen);

    updateIsBrandSplitScreen(!!isSplitScreen);
    if (isSplitScreen) {
      const splitScreen = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.SCREEN_DETAILS) as string
      ) as { brand_ids: [] };

      if (splitScreen?.brand_ids?.length > 0) {
        const splitScreenBrandIds = splitScreen.brand_ids;
        updateActiveHeaderBrands(splitScreenBrandIds);
      }
    } else {
      let headerBrands = ['ALL'];
      const items = localStorage.getItem(STORAGE_KEYS.HEADER_BRANDS) ?? '';
      if (items?.length > 0) {
        headerBrands = JSON.parse(items);
      }
      updateActiveHeaderBrands(headerBrands);
    }
  };

  useEffect(() => {
    const storedPinnedOrders = JSON?.parse(
      localStorage.getItem(STORAGE_KEYS.PINNED_ORDERS) as string
    );
    const filterCompletedOrders = orders?.filter((obj1) =>
      storedPinnedOrders?.some((obj2: OrderType) => obj2.id === obj1.id)
    );
    setPinnedOrders(filterCompletedOrders);
  }, [orders]);

  useEffect(() => {
    const search = searchValue.toUpperCase();
    const activeFilters = getActiveFilters(filters);

    let freshOrders = filterOrdersByActiveFilters(orders, activeFilters, pinnedOrders);
    freshOrders = filterMealBrands(freshOrders, activeHeaderBrands, deliveryType);
    if (searchValue?.length > 0) {
      freshOrders = sortOrdersByLatestTime(filterSearchValue(freshOrders, search));
    }
    if (deliveryType?.length > 0) {
      freshOrders = freshOrders.filter((order) => order?.delivery === deliveryType);
    }

    setPrefilteredOrders(freshOrders);
  }, [activeFilter?.key, pinnedOrders, searchValue, orders, activeHeaderBrands]);

  useEffect(() => {
    let filteredOrders = [...orders];
    filteredOrders = getMealBrands(filteredOrders, activeHeaderBrands, deliveryType, role);
    setPrefilteredOrders(filteredOrders);
  }, [deliveryType]);

  const initFilters = () => {
    const routeFilters =
      FILTERS_BY_ROUTE[location.pathname]?.map((filter) => {
        return { ...filter, number: getFilterCount({ filter, orders, pinnedOrders }) };
      }) ?? [];
    if (routeFilters?.length > 0) {
      const [firstItem, ...others] = routeFilters;
      setFilters([{ ...firstItem, active: true }, ...others]);
      setActiveFilter(firstItem);
    }
  };

  const onFilterClick = (filter: FilterType) => {
    setActiveFilter(filter);
    const newFilters = filters.map((f) => ({
      ...f,
      active: f.title === filter.title ? !f.active : false
    }));
    setFilters(newFilters);
  };

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.PINNED_ORDERS, JSON.stringify(pinnedOrders));
    if (filters.length > 0) {
      const prefiltered = getPrefilteredFilters(filters, orders, pinnedOrders);
      setFilters(prefiltered);
    } else {
      initFilters();
    }
  }, [pinnedOrders, prefilteredOrders]);

  useEffect(() => {
    initFilters();
    initHeaderBrands();
  }, []);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.TEST_MODE, JSON.stringify(testMode));
  }, [testMode]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.DELIVERY_TYPE, JSON.stringify(deliveryType));
  }, [deliveryType]);

  const initPage = async () => {
    const connection = await SocketIOConnection.getConnection();
    await connection.joinRoom(socketConfig.roomName, process.env.REACT_APP_WEBOCKETAPP_KEY);

    connection.onReconnect(async () => {
      await connection.joinRoom(socketConfig.roomName, process.env.REACT_APP_WEBOCKETAPP_KEY);

      loadOrders();
    });
    connection.addEventListener(socketConfig.roomName, 'updated', (order: OrderType) => {
      handleBatchUpdates(order);
    });
    connection.addEventListener(socketConfig.roomName, 'removed', (order: OrderType) => {
      removeOrderIfExist(order);
    });
  };

  const handleBatchUpdates = (updatedOrder: OrderType) => {
    const isValidOrder = activeRoleOrderValidity(socketConfig.page);
    const isOrderValid =
      isValidOrder?.(updatedOrder, cokitchenId ?? '', testMode, screen_id, packaging_screen_id) ??
      false;

    if (isOrderValid) {
      setOrders((prevOrders) => {
        const hasOrder = prevOrders.some((itm) => itm.id === updatedOrder.id);
        let updatedAllOrders = [...prevOrders];
        if (!hasOrder) {
          updatedAllOrders = [...updatedAllOrders, updatedOrder];
        } else {
          const oldOrders = prevOrders.filter((itm) => itm.id !== updatedOrder.id);
          updatedAllOrders = [...oldOrders, updatedOrder];
        }
        return updatedAllOrders.sort(
          (a, b) => Number(getOrderLatestTime(b)) - Number(getOrderLatestTime(a))
        );
      });
    } else {
      removeOrderIfExist(updatedOrder);
    }
  };

  const removeOrderIfExist = (order: OrderType) => {
    setOrders((prevOrders) => prevOrders.filter((item) => item.id !== order.id));
  };

  useEffect(() => {
    const { page, roomName } = socketConfig;
    if (page !== null) {
      loadOrders();
    }
    if (roomName !== '') {
      initPage();
    }
  }, [testMode, cokitchenId, socketConfig]);

  const loadOrders = async () => {
    const { page } = socketConfig;
    if (page === null) return;
    setLoading(true);
    try {
      const fn = activeRoleMapping(socketConfig.page ?? ENUM_PAGES.LIVE)[role];
      const res: { data: OrderType[] } = await fn?.({
        cokitchen_id: cokitchenId,
        test: testMode,
        company: false,
        kitchen_split_screen_id: screen_id,
        packaging_split_screen_id: packaging_screen_id
      });
      res?.data.forEach(handleBatchUpdates);
    } catch (error) {
      errorHandler(error, alert);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LayoutContext.Provider
      value={{
        loading,
        setLoading,
        socketConfig,
        setSocketConfig,
        orders,
        setOrders,
        pinnedOrders,
        setPinnedOrders,
        activePinFilter,
        setActivePinFilter,
        testMode,
        setTestMode,
        deliveryType,
        setDeliveryType,
        receiptToPrint,
        setReceiptToPrint,
        filters,
        setFilters,
        activeFilter,
        setActiveFilter,
        onFilterClick,
        searchValue,
        setSearchValue,
        prefilteredOrders,
        setPrefilteredOrders
      }}>
      {children}
    </LayoutContext.Provider>
  );
});

LayoutProvider.displayName = 'LayoutProvider';

export default LayoutProvider;
