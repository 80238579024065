import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';
import { ENUM_ITEM_TYPES, ENUM_ORDER_PROVIDER, ENUM_STATUS } from './enums';

export type User = {
  username: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  created_at: string;
  email: string;
  loyal_customer: boolean;
};

export type BaseBrandType = {
  name: string;
  id: string;
  item_type: ENUM_ITEM_TYPES;
};

export type BrandType = BaseBrandType & {
  new: boolean;
  logo: string;
  active: boolean;
};

export type ItemType = 'SHOP' | 'FOOD' | 'ALL';

export type AddonType = {
  quantity: string | number;
  name?: string;
  meal_data: {
    id: string;
    amount: number;
    name: string;
    quantity: number;
    is_addon: boolean;
    item_type: ItemType;
  };
};

export type MealType = {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  meal_category_id: string;
  brand_id: string;
  summary: string;
  description: string;
  is_addon: boolean;
  is_combo: boolean;
  amount: string;
  quantity?: number;
  preparation_time: string;
  active: boolean;
  item_no: number;
  new: boolean;
  position: number;
  item_type: ItemType;
  available_no: string;
  availability_soon_time: string;
  availability_soon_date: string;
  availability_soon_date_data: string;
  availability_soon_date_: string;
  minimum_age: string;
  alcohol: boolean;
  nutrional_description: string;
  nutritional_description: string;
  home_page: boolean;
  order_note: string;
  addons: AddonType[];
};
export type BaseCalculatedOrderType = {
  item_type: ENUM_ITEM_TYPES;
  brand_ids: BaseBrandType[];
  meals: BrandMealType[];
  user: User;
  // computed
  originalMeals?: BrandMealType[];
};

export type BrandMealType = {
  amount: number;
  brand: BaseBrandType;
  meals: MealType[];
};

export type CalculatedOrderType = BaseCalculatedOrderType & {
  sub_calculated_order_ids: BaseCalculatedOrderType[];
  cokitchen_id: string;
  pickup: boolean;
  group_sub_order: boolean;
  total_amount: number;
  cokitchen: CoKitchenType;
  address_details: {
    address_line: string;
  };
};

export type OrderPackagingSplitScreen = {
  id: string;
  created_at: string;
  updated_at: string;
  active: boolean;
  name: string;
  active_order_count: number;
  cokitchen_id: string;
};

export type cc = {
  active: boolean;
  condition: (order: OrderType, filter: unknown) => boolean;
  key: 'All' | 'Pinned';
  number: number;
  title: string;
};

export type OrderType = {
  id: string;
  updated_at: string;
  created_at: string;
  cancelled: boolean;
  company: null | boolean;
  kitchen_completed_time: string | null;
  kitchen_dispatched_time: string | null;
  kitchen_verified_time: string | null;
  shop_completed_time: string | null;
  completed_time: string | null;
  order_code: string;
  kitchen_accepted: boolean;
  kitchen_prepared: boolean;
  kitchen_dispatched: boolean;
  shop_accepted: boolean;
  shop_prepared: boolean;
  paid: boolean;
  status: ENUM_STATUS;
  preorder: boolean;
  preorder_delivery_time: string;
  preorder_delivery_date: string;
  preorder_delivery_datetime: string;
  delivery: ENUM_ORDER_PROVIDER;
  calculated_order: CalculatedOrderType;
  user: User;
  meals: BrandMealType[];
  order_note: string;
  order_type: {
    name: string;
  };
  quantity: string;
  amount: number;
  name: string;
  packaging_split_screen: OrderPackagingSplitScreen | null;
  split_screen_firestore_details_packaging_doc_id: string;
  addons: Array<{
    meal_data: {
      name: string;
    };
    name?: string;
    amount: number;
    quantity: string;
  }>;
  kitchen_ready_brand_ids?: string[];
  scheduled: boolean;
  scheduled_delivery_datetime: string;
  kitchen_preparation_time_in_minutes: number;
  kitchen_start_order: string;
  kitchen_split_screen_ids: string[];
  packaging_split_screen_id: string;
};

export type AmPmType = 'AM' | 'PM';
export type CoKitchenPolygons = {
  cokitchen_id: string;
  name: string;
  id: string;
  active: boolean;
  created_at: Date;
  updated_at: Date;
  is_deleted: boolean;
  polygon: null;
  delivery_fee: string;
};

export type SplitScreenType = {
  [key: string]: string;
};

export enum CokitchenTypeEnum {
  pop = 'pop',
  standard = 'standard'
}
export type CoKitchenType = {
  id: string;
  active: boolean;
  name: string;
  images: string[];
  lat: string;
  lng: string;
  address: string;
  posist_data: any;
  delivery_fee: null | number;
  created_at: string;
  updated_at: string;
  phone_number: string;
  cokitchen_type: CokitchenTypeEnum;
  customer_service_phone_number: string[];
  customer_service_whatsapp: string[];
  frontdesk_whatsapp: string[];
  frontdesk_phone_number: string[];
  cokitchen_id: string;
  cokitchen_staff_number: null | string;
  number_of_packaging_screens: string;
  number_of_kitchen_screens: string;
  number_of_frontdesk_screens: string;
  split_screen_firestore_details: any;
  cokitchen_polygons: CoKitchenPolygons[];
};

export type AllowedRoleType = Exclude<ENUM_KITCHEN_ROLE, 'ADMIN'>;

export type StaffType = {
  first_name: string;
  last_name: string;
  id: string;
  department: string;
};
