import React, { useContext } from 'react';
import { FilterType } from '../../pages/live/filters';
import { OrderType, StaffType } from '../../helpers/types';
import { ENUM_PAGES } from '../../helpers/enums';

type LayoutContextType = {
  loading: boolean;
  setLoading: (arg0: boolean) => void;
  socketConfig: { roomName: string; page: ENUM_PAGES | null };
  setSocketConfig: (arg0: { roomName: string; page: ENUM_PAGES | null }) => void;
  orders: OrderType[];
  setOrders: (item: OrderType[]) => void;
  pinnedOrders: OrderType[];
  setPinnedOrders: (item: OrderType[]) => void;
  activePinFilter: FilterType | null;
  setActivePinFilter: (item: FilterType | null) => void;
  testMode: boolean;
  setTestMode: (show: boolean) => void;
  deliveryType: string;
  setDeliveryType: (type: string) => void;
  setReceiptToPrint: (arg0: { ref: any; shouldPrint: boolean; order: OrderType | null }) => void;
  receiptToPrint: { ref: any; shouldPrint: boolean; order: OrderType | null };
  filters: FilterType[];
  setFilters: (filter: FilterType[]) => void;
  activeFilter?: FilterType;
  setActiveFilter: (filter: FilterType) => void;
  onFilterClick: (filter: FilterType) => void;
  searchValue: string;
  setSearchValue: (e: any) => void;
  prefilteredOrders: OrderType[];
  setPrefilteredOrders: (item: OrderType[]) => void;
};
export const LayoutContext = React.createContext<LayoutContextType>({
  loading: false,
  setLoading: () => {},
  socketConfig: { roomName: '', page: null },
  setSocketConfig: () => {},
  orders: [],
  setOrders: () => {},
  pinnedOrders: [],
  setPinnedOrders: () => {},
  activePinFilter: null,
  setActivePinFilter: () => {},
  testMode: false,
  setTestMode: () => {},
  deliveryType: '',
  setDeliveryType: () => {},
  receiptToPrint: {
    ref: null,
    shouldPrint: false,
    order: null
  },
  filters: [],
  setFilters: () => {},
  activeFilter: undefined,
  setActiveFilter: () => {},
  onFilterClick: () => {},
  setReceiptToPrint: () => {},
  searchValue: '',
  setSearchValue: () => {},
  prefilteredOrders: [],
  setPrefilteredOrders: () => {}
});
const useLayoutContext = (): LayoutContextType => {
  const context = useContext(LayoutContext);
  return context;
};

export default useLayoutContext;
